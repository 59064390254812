import React from 'react';
import { motion } from 'framer-motion';

const FeatureCard = ({ feature, index }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ y: -8 }}
            className="relative group"
        >
            <div className="absolute inset-0 bg-gradient-to-r from-green-400/20 to-blue-400/20 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            <div className="relative bg-gray-800/50 backdrop-blur-lg rounded-2xl p-8 h-full border border-gray-700 group-hover:border-green-400/30 transition-colors duration-300">
                <div className="w-16 h-16 mb-6 rounded-2xl bg-gradient-to-r from-green-400 to-blue-400 p-0.5">
                    <div className="bg-gray-800 w-full h-full rounded-2xl flex items-center justify-center">
                        {feature.icon}
                    </div>
                </div>
                <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-green-300 to-blue-300 bg-clip-text text-transparent">
                    {feature.title}
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                    {feature.description}
                </p>
            </div>
        </motion.div>
    );
};

const FeaturesSection = ({ features }) => {
    return (
        <section className="py-12 relative">
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />

            {/* Main content container */}
            <div className="max-w-7xl mx-auto px-6 sm:px-8 relative z-10">
                {/* Heading */}
                <motion.h2
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    className="text-4xl font-bold text-center bg-gradient-to-r from-green-300 to-blue-300 bg-clip-text text-transparent mb-16"
                >
                    Why us?
                </motion.h2>

                {/* Features grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} feature={feature} index={index} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHeart, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const location = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <footer className="relative bg-gradient-to-r from-slate-900 to-slate-800 shadow-lg backdrop-blur-sm bg-opacity-80">
            <div className="max-w-7xl mx-auto px-4 py-4"> {/* Significantly reduced padding */}
                {/* Main footer content */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-white"> {/* Reduced gap */}
                    {/* Company info */}
                    <div className="text-center md:text-left space-y-2"> {/* Reduced spacing */}
                        <h3 className="text-lg font-bold bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                            VisionDecode
                        </h3>
                        <p className="text-slate-300 text-xs">
                            Empowering the future through innovative solutions
                        </p>
                        <div className="flex justify-center md:justify-start space-x-2"> {/* Reduced space */}
                            {[
                                { icon: FaInstagram, href: 'https://www.instagram.com/vision.decode', label: 'GitHub' },
                                // { icon: FaTwitter, href: '', label: 'Twitter' },
                                { icon: FaLinkedin, href: 'https://www.linkedin.com/company/vision-decode', label: 'LinkedIn' }
                            ].map(({ icon: Icon, href, label }) => (
                                <a
                                    key={label}
                                    href={href}
                                    className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                                    aria-label={label}
                                >
                                    <Icon className="text-base hover:scale-110 transform transition-transform duration-300" />
                                </a>
                            ))}
                        </div>
                    </div>

                    {/* Quick links */}
                    <div className="text-center space-y-2"> {/* Reduced spacing */}
                        <h4 className="text-base font-semibold bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                            Quick Links
                        </h4>
                        <div className="flex flex-col space-y-1"> {/* Reduced space */}
                            {[
                                { to: '/about', label: 'About Us' },
                                { to: '/contact', label: 'Contact' },
                                { to: '/privacy-policy', label: 'Privacy Policy' },
                                { to: '/website-terms', label: 'Terms of Service' }
                            ].map(({ to, label }) => (
                                <Link
                                    key={label}
                                    to={to}
                                    className="text-slate-300 hover:text-blue-400 transition-colors duration-300 text-xs"
                                >
                                    {label}
                                </Link>
                            ))}
                        </div>
                    </div>

                    {/* Contact section */}
                    <div className="text-center md:text-right space-y-2"> {/* Reduced spacing */}
                        <h4 className="text-base font-semibold bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                            Get in Touch
                        </h4>
                        <div className="space-y-1 text-xs text-slate-300"> {/* Reduced text size */}
                            <p>Email: info@visiondecode.com</p>
                        </div>
                    </div>
                </div>

                {/* Bottom bar */}
                <div className="mt-4 pt-2 border-t border-gradient-to-r from-blue-400/20 to-green-400/20"> {/* Further reduced spacing */}
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-1 md:space-y-0"> {/* Tighter layout */}
                        <p className="text-slate-400 text-xs">
                            &copy; {currentYear} VisionDecode. All rights reserved.
                        </p>
                        <p className="text-slate-400 text-xs flex items-center space-x-1">
                            <span>Made with</span>
                            <FaHeart className="text-red-500 animate-pulse" />
                            <span>by VisionDecode Team</span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React from 'react';
import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';

const AboutSection = () => {
    return (
        <section className="bg-gradient-to-b from-gray-900 to-gray-800 py-24 text-white">
            <div className="max-w-7xl mx-auto px-6 sm:px-8">
                {/* Vision Statement */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                    className="text-center space-y-8"
                >
                    <Quote className="h-12 w-12 text-teal-300 mx-auto opacity-50" />

                    <h4 className="text-4xl font-bold tracking-tight">
                        We are <span className="text-teal-300">dedicated</span> to creating
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-300"> solutions </span>
                        for global challenges.
                    </h4>

                    <h4 className="text-4xl font-bold tracking-tight">
                        Our vision is to <span className="text-teal-300">innovate</span> and deliver the
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-300"> highest quality </span>
                        products to our customers.
                    </h4>

                    <Quote className="h-12 w-12 text-teal-300 mx-auto opacity-50 rotate-180" />
                </motion.div>

                {/* Team Section */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="text-center mt-24 space-y-6"
                >
                    <h2 className="text-3xl font-bold text-teal-300">Backed by great minds in Innovation</h2>
                    <p className="text-gray-300 max-w-2xl mx-auto text-lg leading-relaxed">
                        Our team is a powerhouse of talent, comprising graduates from prestigious institutions like IITs, NITs, and IIITs,
                        and seasoned professionals with rich industry experience from global leaders such as Google, Flipkart, and Gojek.
                        With expertise spanning diverse industries and a shared passion for innovation, we are committed to creating impact-driven solutions that set new benchmarks.
                        Backed by decades of collective experience, we ensure every product we deliver upholds the highest standards of quality, excellence, and relevance.
                    </p>
                </motion.div>

                {/* Founders Section */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="mt-24"
                >
                    <h2 className="text-3xl font-bold text-center text-teal-300 mb-12">Meet Our Experts</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {[
                            {
                                name: "Mandeep Singh",
                                role: "Google, Ex-Adobe, NIT",
                                image: "./mandeep.jpeg",
                                bio: "Mandeep is an expert in market analysis and product development, ensuring that our products align with customer needs and industry trends."
                            },
                            {
                                name: "Anmol Dhingra",
                                role: "Ex-Flipkart, Thapar",
                                image: "./anmol.jpeg",
                                bio: "With over 7 years of experience in software development, Anmol focuses on leveraging cutting-edge technologies to deliver outstanding products."
                            },
                            {
                                name: "Rahul Singh",
                                role: "IIT",
                                image: "./rahul.jpg",
                                bio: "Rahul has a deep passion for technology and innovation, leading the team with a vision to drive impactful changes in the tech industry."
                            },
                            {
                                name: "Saurabh Jain",
                                role: "Ex-Gojek, IIIT",
                                image: "./saurabh.jpeg",
                                bio: "Saurabh specializes in navigating the complexities of frameworks to ensure our solutions are compliant with industry standards and regulations."
                            }
                        ].map((founder, index) => (
                            <motion.div
                                key={founder.name}
                                initial={{opacity: 0, y: 20}}
                                whileInView={{opacity: 1, y: 0}}
                                viewport={{once: true}}
                                transition={{duration: 0.6, delay: 0.2 * index}}
                                className="group"
                            >
                                <div
                                    className="bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:bg-gray-700 h-full flex flex-col items-center">
                                    {/* Image container with gradient effect */}
                                    <div className="relative mb-4">
                                        <div
                                            className="absolute inset-0 bg-gradient-to-b from-teal-300/20 to-transparent rounded-full transform transition-transform duration-300 group-hover:scale-105"/>
                                        <img
                                            src={founder.image}
                                            alt={`${founder.name}'s portrait`}
                                            className="w-42 h-42 rounded-full border-2 border-gray-100 shadow-md mx-auto relative z-10 transform transition-transform duration-300 group-hover:scale-105"
                                        />
                                    </div>

                                    {/* Name */}
                                    <h4 className="text-xl font-bold text-white text-center mb-2">{founder.name}</h4>

                                    {/* Role */}
                                    <p className="text-teal-300 font-medium text-center mb-4">{founder.role}</p>

                                    {/* Bio */}
                                    <p className="text-gray-300 text-center leading-relaxed">
                                        {founder.bio}
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default AboutSection;
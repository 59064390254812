import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaPhoneAlt, FaBars, FaTimes, FaUserTie, FaChevronDown } from 'react-icons/fa';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [talentMenuOpen, setTalentMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 20;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const toggleTalentMenu = () => setTalentMenuOpen(!talentMenuOpen);

    return (
        <nav
            className={`fixed w-full top-0 z-50 transition-all duration-300 ${
                scrolled
                    ? 'bg-gray-900 backdrop-blur-lg bg-opacity-90 shadow-lg'
                    : 'bg-transparent backdrop-blur-none'
            }`}
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    {/* Logo Section */}
                    <div className="flex items-center">
                        <Link
                            to="/"
                            className="group flex items-center space-x-2 text-white"
                            aria-label="Home"
                        >
                            <FaHome className="text-2xl transform transition-all duration-300 group-hover:scale-110 group-hover:rotate-12 group-hover:text-blue-400" />
                            <span className="font-bold text-xl hidden sm:block transform transition-all duration-300 group-hover:text-blue-400">
                                VisionDecode
                            </span>
                        </Link>
                    </div>

                    {/* Hamburger Menu */}
                    <div className="flex lg:hidden">
                        <button
                            onClick={toggleMenu}
                            className="text-white p-2 rounded-lg hover:bg-slate-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                            aria-label="Toggle Menu"
                        >
                            {menuOpen ? (
                                <FaTimes className="text-2xl animate-spin-once" />
                            ) : (
                                <FaBars className="text-2xl" />
                            )}
                        </button>
                    </div>

                    {/* Desktop Navigation */}
                    <ul className="hidden lg:flex space-x-8">
                        {[
                            { to: '/about', label: 'About', icon: <FaInfoCircle /> },
                            { to: '/contact', label: 'Contact', icon: <FaPhoneAlt /> },
                        ].map(({ to, label, icon }) => (
                            <li key={label}>
                                <Link
                                    to={to}
                                    className="group flex items-center space-x-2 text-white hover:text-blue-400 transition-all duration-300"
                                >
                                    <span className="transform transition-all duration-300 group-hover:scale-110">
                                        {icon}
                                    </span>
                                    <span className="font-medium text-sm uppercase tracking-wider transform transition-all duration-300 group-hover:translate-x-1">
                                        {label}
                                    </span>
                                </Link>
                            </li>
                        ))}

                        {/* Apply as Talent with Dropdown */}
                        <li className="relative">
                            <button
                                onClick={toggleTalentMenu}
                                className="group flex items-center space-x-2 text-white hover:text-blue-400 transition-all duration-300 focus:outline-none"
                            >
                                <FaUserTie className="transform transition-all duration-300 group-hover:scale-110" />
                                <span className="font-medium text-sm uppercase tracking-wider transform transition-all duration-300 group-hover:translate-x-1">
                                    Talent Hiring
                                </span>
                                <FaChevronDown
                                    className={`ml-1 transition-transform ${
                                        talentMenuOpen ? 'rotate-180' : ''
                                    }`}
                                />
                            </button>
                            {talentMenuOpen && (
                                <ul className="absolute left-0 mt-2 bg-slate-800 text-white rounded-lg shadow-lg py-2 z-50">
                                    <li>
                                        <Link
                                            to="/application-form"
                                            className="block px-4 py-2 hover:bg-slate-700 transition-colors duration-300"
                                            onClick={() => setTalentMenuOpen(false)}
                                        >
                                            Join our team
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/hiring-process"
                                            className="block px-4 py-2 hover:bg-slate-700 transition-colors duration-300"
                                            onClick={() => setTalentMenuOpen(false)}
                                        >
                                            Interview Process
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </div>

                {/* Mobile Menu */}
                <div
                    className={`lg:hidden transition-all duration-300 ease-in-out ${
                        menuOpen
                            ? 'max-h-screen opacity-100'
                            : 'max-h-0 opacity-0 pointer-events-none'
                    } overflow-hidden bg-slate-900 text-white fixed top-16 left-0 w-full z-40`}
                >
                    <ul className="py-4 space-y-4 px-4">
                        {[
                            { to: '/about', label: 'About', icon: <FaInfoCircle /> },
                            { to: '/contact', label: 'Contact', icon: <FaPhoneAlt /> },
                        ].map(({ to, label, icon }) => (
                            <li key={label}>
                                <Link
                                    to={to}
                                    className="flex items-center space-x-3 px-4 py-3 rounded-lg text-white hover:bg-slate-700 transition-colors duration-300"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <span className="text-lg">{icon}</span>
                                    <span className="font-medium text-base">{label}</span>
                                </Link>
                            </li>
                        ))}

                        {/* Apply as Talent Dropdown */}
                        <li>
                            <div className="px-4 py-3 flex flex-col space-y-2 bg-slate-800 rounded-lg">
                                <span className="font-medium text-base">Apply as Talent</span>
                                <ul className="space-y-2 pl-6">
                                    <li>
                                        <Link
                                            to="/application-form"
                                            className="block py-1 text-sm hover:text-blue-400"
                                            onClick={() => setMenuOpen(false)}
                                        >
                                            Application Form
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/hiring-process"
                                            className="block py-1 text-sm hover:text-blue-400"
                                            onClick={() => setMenuOpen(false)}
                                        >
                                            Hiring Process
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {motion} from "framer-motion";
import {useNavigate} from 'react-router-dom';

const ProcessTimeline = ({ steps }) => {
    const navigate = useNavigate();

    return (
        <section className="py-12 relative">
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />

            {/* Main content container */}
            <div className="max-w-7xl mx-auto px-6 sm:px-8 relative z-10">
                {/* Heading */}
                <motion.h2
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-4xl font-bold text-center bg-gradient-to-r from-green-300 to-blue-300 bg-clip-text text-transparent mb-16"
                >
                    Ready to Begin? Here’s How!
                </motion.h2>

                {/* Timeline */}
                <VerticalTimeline>
                    {steps.map((step, index) => (
                        <VerticalTimelineElement
                            key={index}
                            className="vertical-timeline-element--work"
                            contentStyle={{
                                background: 'rgba(55, 65, 81, 0.8)',
                                backdropFilter: 'blur(12px)',
                                borderRadius: '16px',
                                border: '1px solid rgba(74, 222, 128, 0.1)',
                                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                            }}
                            contentArrowStyle={{
                                borderRight: '7px solid rgba(55, 65, 81, 0.8)',
                            }}
                            iconStyle={{
                                background: 'rgb(74,153,209)',
                                boxShadow: '0 0 0 4px rgba(34, 197, 94, 0.2)',
                            }}
                            icon={index % 2 === 1 ? <ChevronRightIcon className="w-6 h-6"/> : <ChevronLeftIcon className="w-6 h-6"/>}
                        >
                            {/* Step content */}
                            <h3 className="text-2xl font-bold bg-gradient-to-r from-green-300 to-blue-300 bg-clip-text text-transparent mb-4">
                                {step.label}
                            </h3>
                            <p className="text-gray-300 text-lg leading-relaxed">{step.description}</p>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>

                {/* Button Section */}
                <div className="flex justify-center mt-12">
                    <button
                        onClick={() => navigate('/contact')}
                        className="group relative px-8 py-4 rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-400 text-gray-900 font-bold text-lg"
                    >
                        <span className="relative flex items-center gap-2">
                            Book Your Free Discovery Call Now
                            <ChevronRightIcon className="w-5 h-5" />
                        </span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default ProcessTimeline;

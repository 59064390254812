import React, { useState } from 'react';
import {
    User,
    Mail,
    Phone,
    Building,
    MessageSquare,
} from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc } from 'firebase/firestore';
import {db} from "../firebase/config";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);

    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPhoneNumber = (phone) => /^[0-9]+$/.test(phone);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!isValidPhoneNumber(formData.phone)) {
            newErrors.phone = 'Phone number must be numeric';
        }
        if (!formData.company) newErrors.company = 'Company is required';
        if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            toast.error('Please fill in all required fields correctly.', {
                position: "top-right",
                theme: "dark",
                className: "bg-slate-800 text-slate-200",
            });
            return;
        }

        setLoading(true);
        try {

            await addDoc(collection(db, 'inquiries'), {
                ...formData,
                createdAt: new Date()
            });
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                message: ''
            });
            toast.success('Thank you for your message! We will get back to you soon.', {
                position: "top-right",
                theme: "dark",
                className: "bg-slate-800 text-slate-200",
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form. Please try again.', {
                position: "top-right",
                theme: "dark",
                className: "bg-slate-800 text-slate-200",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 py-16 px-4">
            <div className="max-w-3xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                        Get in Touch
                    </h1>
                    <p className="text-slate-300 text-lg">
                        We'd love to hear from you. Send us a message and we'll respond as soon as possible.
                    </p>
                </div>

                {/* Form */}
                <form onSubmit={handleSubmit} className="space-y-8 bg-slate-800/50 p-8 rounded-xl shadow-xl backdrop-blur-sm">
                    {/* Personal Information Section */}
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold text-blue-400 flex items-center gap-2">
                            <User className="w-5 h-5" />
                            Contact Information
                        </h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <User className="w-4 h-4 inline mr-2" />
                                    Full Name *
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className={`w-full px-4 py-2 rounded-lg bg-slate-900/50 border ${errors.name ? 'border-red-500' : 'border-slate-700'} text-slate-300 focus:outline-none focus:border-blue-500 transition-colors`}
                                />
                                {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <Mail className="w-4 h-4 inline mr-2" />
                                    Email Address *
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className={`w-full px-4 py-2 rounded-lg bg-slate-900/50 border ${errors.email ? 'border-red-500' : 'border-slate-700'} text-slate-300 focus:outline-none focus:border-blue-500 transition-colors`}
                                />
                                {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <Phone className="w-4 h-4 inline mr-2" />
                                    Phone Number *
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    className={`w-full px-4 py-2 rounded-lg bg-slate-900/50 border ${errors.phone ? 'border-red-500' : 'border-slate-700'} text-slate-300 focus:outline-none focus:border-blue-500 transition-colors`}
                                />
                                {errors.phone && <p className="mt-1 text-sm text-red-500">{errors.phone}</p>}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <Building className="w-4 h-4 inline mr-2" />
                                    Company Name *
                                </label>
                                <input
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    required
                                    className={`w-full px-4 py-2 rounded-lg bg-slate-900/50 border ${errors.company ? 'border-red-500' : 'border-slate-700'} text-slate-300 focus:outline-none focus:border-blue-500 transition-colors`}
                                />
                                {errors.company && <p className="mt-1 text-sm text-red-500">{errors.company}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Message Section */}
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold text-blue-400 flex items-center gap-2">
                            <MessageSquare className="w-5 h-5" />
                            Your Message
                        </h2>

                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-2">
                                Message *
                            </label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows={6}
                                required
                                className={`w-full px-4 py-2 rounded-lg bg-slate-900/50 border ${errors.message ? 'border-red-500' : 'border-slate-700'} text-slate-300 focus:outline-none focus:border-blue-500 transition-colors resize-none`}
                                placeholder="How can we help you today?"
                            />
                            {errors.message && <p className="mt-1 text-sm text-red-500">{errors.message}</p>}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full py-3 px-6 rounded-lg bg-gradient-to-r from-blue-500 to-green-500 text-white font-semibold hover:opacity-90 transition-opacity disabled:opacity-50"
                        >
                            {loading ? 'Sending...' : 'Send Message'}
                        </button>
                        <p className="text-sm text-slate-400 text-center mt-4">
                            By submitting this form, you agree to our privacy policy and terms of service.
                        </p>
                    </div>
                </form>

                {/* Toast Container */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
        </div>
    );
};

export default ContactForm;
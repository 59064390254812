import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import ContactForm from './components/ContactForm';
import './App.css';
import WebsiteTerms from "./components/WebsiteTerms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Footer from "./components/Footer";
import AboutSection from "./components/AboutSection";
import HiringProcess from "./components/HiringProcess";
import ApplicationForm from "./components/ApplicationForm";
import ReactGA from 'react-ga';


ReactGA.initialize('G-93DG9C4KCW');

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Router>
            <div className="App">
                <Navbar />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/contact" element={<ContactForm />} />
                        <Route path="/about" element={<AboutSection />} />
                        <Route path="/hiring-process" element={<HiringProcess />} />
                        <Route path="/application-form" element={<ApplicationForm />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/website-terms" element={<WebsiteTerms />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}


export default App;
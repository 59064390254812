import React, { useEffect, useState } from 'react';
import {
    FileSearch,
    Binary,
    Code2,
    Users,
    UserCheck,
    CheckCircle,
    ArrowRight,
    Percent
} from 'lucide-react';

const HiringProcess = () => {
    const [activeStep, setActiveStep] = useState(0);
    const totalSteps = 5;

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prev) => (prev + 1) % totalSteps);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    const steps = [
        {
            icon: FileSearch,
            title: "Profile Screening",
            description: "Thorough evaluation of experience, skills, and background",
            stats: "Initial assessment phase"
        },
        {
            icon: Binary,
            title: "Online Screening",
            description: "Skill based algorithmic problem-solving",
            stats: "Technical evaluation phase"
        },
        {
            icon: Code2,
            title: "Practical Challenge",
            description: "Real-world challenge with system design focus",
            stats: "Applied skills assessment"
        },
        {
            icon: Users,
            title: "Pair Programming",
            description: "In-person collaborative coding and problem solving",
            stats: "Collaborative evaluation"
        },
        {
            icon: UserCheck,
            title: "Culture Fit",
            description: "Final assessment of team compatibility and values alignment",
            stats: "Final evaluation stage"
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 py-16 px-4">
            <div className="max-w-6xl mx-auto">
                {/* Header Section */}
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                        Our Hiring Process
                    </h1>
                    <p className="text-slate-300 text-lg mb-8">
                        Building exceptional teams through a comprehensive evaluation process
                    </p>
                    <div className="flex items-center justify-center space-x-2 text-green-400">
                        <CheckCircle className="w-6 h-6" />
                        <span className="text-xl font-semibold">Excellence in Every Hire</span>
                    </div>
                </div>

                {/* Flowchart Section */}
                <div className="relative">
                    {/* Steps */}
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center relative">
                        {steps.map((step, index) => {
                            const Icon = step.icon;
                            const isActive = index === activeStep;

                            return (
                                <div key={index} className="flex-1 relative">
                                    {/* Step Content */}
                                    <div className={`
                                        transition-all duration-500 ease-in-out
                                        ${isActive ? 'scale-110 z-10' : 'scale-100 z-0'}
                                        flex flex-col items-center text-center p-6 rounded-lg
                                        ${isActive ? 'bg-slate-800/80' : 'bg-slate-800/40'}
                                        mx-4 mb-8 md:mb-0
                                    `}>
                                        {/* Icon */}
                                        <div className={`
                                            p-4 rounded-full mb-4
                                            ${isActive ? 'bg-blue-500 text-white' : 'bg-slate-700 text-slate-300'}
                                            transition-colors duration-300
                                        `}>
                                            <Icon className="w-8 h-8" />
                                        </div>

                                        {/* Title */}
                                        <h3 className={`
                                            text-lg font-semibold mb-2
                                            ${isActive ? 'text-blue-400' : 'text-slate-300'}
                                        `}>
                                            {step.title}
                                        </h3>

                                        {/* Description */}
                                        <p className="text-slate-400 text-sm mb-4">
                                            {step.description}
                                        </p>

                                        {/* Stats */}
                                        <div className={`
                                            text-sm font-medium
                                            ${isActive ? 'text-green-400' : 'text-slate-500'}
                                        `}>
                                            {step.stats}
                                        </div>
                                    </div>

                                    {/* Connector Line */}
                                    {index < steps.length - 1 && (
                                        <div className="hidden md:block absolute top-1/2 -right-4 transform -translate-y-1/2 w-8">
                                            <ArrowRight className={`
                                                w-8 h-8
                                                ${isActive ? 'text-blue-400' : 'text-slate-600'}
                                                transition-colors duration-300
                                            `} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Call to Action */}
                <div className="mt-16 text-center">
                    <div className="space-y-4">
                        <div className="inline-flex items-center space-x-2 bg-green-500/10 px-6 py-3 rounded-full">
                            <Percent className="w-6 h-6 text-green-400" />
                            <span className="text-green-400 font-semibold">
                                Join our exceptional team of innovators
                            </span>
                        </div>
                        <div className="mt-6">
                            <a
                                href="/application-form"
                                className="inline-flex items-center gap-2 bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                            >
                                <span>Apply Now</span>
                                <ArrowRight className="w-5 h-5" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HiringProcess;
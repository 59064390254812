import React from 'react';
import {ChevronRight, ArrowRight} from 'lucide-react';
import {useNavigate} from 'react-router-dom';

const LogoSection = () => {
    const navigate = useNavigate();

    return (
        <section className="min-h-screen flex items-center justify-center py-12 px-6 relative">
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />
            <div className="max-w-5xl mx-auto text-center relative z-10">
                <div className="relative inline-block mb-10">
                    <div className="relative">
                        <img
                            src="/homelogo.png"
                            alt="VisionDecode"
                            className="w-52 h-64"
                        />
                    </div>
                </div>

                <h1 className="text-6xl md:text-7xl font-bold mb-8 leading-tight bg-gradient-to-r from-green-300 via-blue-300 to-green-300 bg-clip-text text-transparent">
                    Your vision <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-300">Our code</span>
                </h1>

                <p className="text-2xl text-gray-300 mb-12 max-w-3xl mx-auto leading-relaxed">
                    You focus on your expertise — we’ve got your tech covered.
                </p>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <button
                        onClick={() => navigate('/contact')}
                        className="group relative px-8 py-4 rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-400 text-gray-900 font-bold text-lg"
                    >
                        <span className="relative flex items-center gap-2">
                            Free Consultation
                            <ChevronRight className="w-5 h-5" />
                        </span>
                    </button>

                    <button
                        onClick={() => {
                            const section = document.getElementById('services');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}
                        className="group relative px-8 py-4 rounded-full overflow-hidden border-2 border-green-400/30 hover:border-green-400 font-bold text-lg text-white transition-colors duration-300"
                    >
                        <span className="relative flex items-center gap-2">
                            What we offer
                            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                        </span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default LogoSection;

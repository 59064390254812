import React from 'react';
import {
    Users,
    Code,
    Zap,
    Shield,
    DollarSign,
    Cloud,
    Code2,
    Palette,
    ChartNoAxesCombined,
    Rocket,
    Bot,
    HandHelping,
} from 'lucide-react';
import 'react-vertical-timeline-component/style.min.css';

import ProcessTimeline from "./homepage/ProcessTimeline";
import LogoSection from "./homepage/LogoSection";
import FeaturesSection from "./homepage/FeaturesSection";
import StatsSection from "./homepage/StatsSection";


const HomePage = () => {
    const services = [
        {
            icon: <Code2 className="w-8 h-8 text-white"/>,
            value: "Web Applications",
            label: "Build unique, customized web applications perfectly tailored to your needs."
        },
        {
            icon: <Palette className="w-8 h-8 text-white"/>,
            value: "Website Revamp",
            label: "Renew and improve your website's design for a fresh look with reliable hosting services."
        },
        {
            icon: <Bot className="w-8 h-8 text-white"/>,
            value: "Custom Chat Bots",
            label: "Creating automated bots for better user interaction and superior customer support."
        },
        {
            icon: <ChartNoAxesCombined className="w-8 h-8 text-white"/>,
            value: "Data Analytics",
            label: "Unlock insights and drive business decisions with advanced data analysis techniques."
        },
        {
            icon: <Rocket className="w-8 h-8 text-white"/>,
            value: "Automations",
            label: "Tailored automations crafted to streamline your repetitive tasks and enhance productivity."
        },
        {
            icon: <HandHelping className="w-8 h-8 text-white"/>,
            value: "AI Strategy Consulting",
            label: "Experienced tech advisors assessing your needs and recommending AI solutions."
        }
    ];


    const features = [
        {
            icon: <Users className="w-6 h-6 text-white"/>,
            title: "Highly experienced team",
            description: "Our rigorous hiring process ensures that only top-tier talent joins our team, delivering unmatched expertise and reliability."
        },
        {
            icon: <DollarSign className="w-6 h-6 text-white"/>,
            title: "Transparent Cost Breakdown",
            description: "Get a clear, detailed cost breakdown upfront. We proceed only when you're ready to move forward with confidence."
        },
        {
            icon: <Shield className="w-6 h-6 text-white"/>,
            title: "Secure & Reliable",
            description: "Built with the latest security standards and proven technologies you can trust."
        },
        {
            icon: <Code className="w-6 h-6 text-white"/>,
            title: "Custom Development",
            description: "Tailored solutions built specifically for your business needs and goals."
        },
        {
            icon: <Cloud className="w-6 h-6 text-white"/>,
            title: "Cloud Integration",
            description: "Seamless cloud services integration for scalable and reliable applications."
        },
        {
            icon: <Zap className="w-6 h-6 text-white"/>,
            title: "Trackable Milestones, Fast Delivery",
            description: "Navigate your project smoothly with clear milestones, regular syncs, and fast, efficient delivery every step of the way."
        }
    ];

    const steps = [
        {label: "Step 1: Free Consultation", description: "Discuss project goals and requirements without any cost."},
        {label: "Step 2: Planning", description: "Outline project timelines, resources, and key milestones."},
        {label: "Step 3: Development", description: "Our team builds your solution with best practices."},
        {label: "Step 4: Testing & Launch", description: "Rigorous testing ensures quality; then, we launch."},
        {label: "Step 5: Support & Maintenance", description: "Ongoing support to keep your project running smoothly."}
    ];

    return (
        <div
            className="min-h-screen bg-gradient-to-b from-gray-800 via-gray-700 to-gray-800 text-white relative overflow-hidden">
            <LogoSection/>
            <div id="services">
                <StatsSection stats={services}/>
            </div>
            <FeaturesSection features={features}/>
            {/*<TrustedClients/>*/}
            <ProcessTimeline steps={steps}/>

        </div>
    );
};

export default HomePage;

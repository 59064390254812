import React from 'react';

const PolicyCard = ({ title, children }) => (
    <div className="backdrop-blur-sm bg-white/5 rounded-lg p-6 mb-6 border border-white/10 hover:border-white/20 transition-colors">
        <div className="flex items-center gap-3 mb-4">
            <div className="p-2 rounded-full bg-white/10 border border-white/10">
                <svg
                    className="w-5 h-5 text-blue-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                </svg>
            </div>
            <h3 className="text-2xl font-semibold text-blue-300">{title}</h3>
        </div>
        <p className="text-gray-300 leading-relaxed pl-11">{children}</p>
    </div>
);

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
            <div className="max-w-4xl mx-auto px-6 sm:px-8 py-12 flex-grow">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <div className="mx-auto w-16 h-16 bg-white/5 rounded-full flex items-center justify-center mb-6 border border-white/10">
                        <svg
                            className="w-8 h-8 text-blue-300"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                        </svg>
                    </div>
                    <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                        Privacy Policy
                    </h1>
                    <p className="text-lg text-gray-300 max-w-2xl mx-auto leading-relaxed">
                        At Vision Decode, we respect your privacy. This Privacy Policy explains how we collect, use, and protect your information.
                    </p>
                </div>

                {/* Privacy Sections */}
                <div className="space-y-6">
                    <PolicyCard title="Information We Collect">
                        We collect personal information such as your name, email address, and any other details you provide when you fill out forms on our website.
                    </PolicyCard>

                    <PolicyCard title="How We Use Your Information">
                        We use the information to provide better services, send relevant updates, and improve the user experience on our website.
                    </PolicyCard>

                    <PolicyCard title="Your Rights">
                        You have the right to access, modify, or delete your personal information at any time by contacting us.
                    </PolicyCard>
                </div>

                {/* Footer */}
                <div className="mt-12 text-center">
                    <div className="inline-block px-4 py-2 rounded-full bg-white/5 border border-white/10">
                        <p className="text-sm text-gray-400">
                            Last updated: January 2024
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
import React, {useState} from 'react';
import {
    Linkedin,
    Briefcase,
    Mail,
    Phone,
    FileText,
    User,
    Github,
    Globe
} from 'lucide-react';
import {collection, addDoc} from 'firebase/firestore';
import {db} from "../firebase/config";
import {toast, ToastContainer} from "react-toastify";

const ApplicationForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        linkedIn: '',
        github: '',
        portfolio: '',
        experience: '',
        coverLetter: '',
        additionalNotes: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);


    const experienceLevels = [
        'Entry Level (0-2 years)',
        'Mid Level (2-5 years)',
        'Senior Level (5-8 years)',
        'Lead Level (8+ years)'
    ];

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {

            // Save form data to Firestore
            await addDoc(collection(db, 'applications'), {
                ...formData,
                submittedAt: new Date()
            });

            toast.success("Thank you for applying! We'll be in touch if the perfect project match comes your way. 🚀", {
                position: "top-right",
                theme: "dark",
                className: "bg-slate-800 text-slate-200",
            });
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                linkedIn: '',
                github: '',
                portfolio: '',
                experience: '',
                coverLetter: '',
                additionalNotes: '',
            });
        } catch (error) {
            console.error('Error submitting application:', error);
            toast.error('Application submission failed. Please try again or contact us at info@visiondecode.com for assistance.', {
                position: "top-right",
                theme: "dark",
                className: "bg-slate-800 text-slate-200",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 py-16 px-4">
            <div className="max-w-3xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-green-400 bg-clip-text text-transparent">
                        Join Our Elite Team
                    </h1>
                    <p className="text-slate-300 text-lg">
                        Take the first step towards joining the exceptional tech talent
                    </p>
                </div>

                {/* Form */}
                <form className="space-y-8 bg-slate-800/50 p-8 rounded-xl shadow-xl backdrop-blur-sm"
                      onSubmit={handleSubmit}>
                    {/* Personal Information Section */}
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold text-blue-400 flex items-center gap-2">
                            <User className="w-5 h-5"/>
                            Personal Information
                        </h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    First Name *
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    Last Name *
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <Mail className="w-4 h-4 inline mr-2"/>
                                    Email Address *
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    <Phone className="w-4 h-4 inline mr-2"/>
                                    Phone Number *
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Professional Information */}
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold text-blue-400 flex items-center gap-2">
                            <Briefcase className="w-5 h-5"/>
                            Professional Information
                        </h2>

                        <div className="space-y-6">
                            {/* Professional Links */}
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-slate-300 mb-2">
                                        <Linkedin className="w-4 h-4 inline mr-2"/>
                                        LinkedIn Profile *
                                    </label>
                                    <input
                                        type="url"
                                        required
                                        name="linkedIn"
                                        value={formData.linkedIn}
                                        onChange={handleInputChange}
                                        placeholder="https://linkedin.com/in/your-profile"
                                        className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-slate-300 mb-2">
                                        <Github className="w-4 h-4 inline mr-2"/>
                                        GitHub Profile
                                    </label>
                                    <input
                                        type="url"
                                        name="github"
                                        value={formData.github}
                                        onChange={handleInputChange}
                                        placeholder="https://github.com/your-username"
                                        className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-slate-300 mb-2">
                                        <Globe className="w-4 h-4 inline mr-2"/>
                                        Portfolio Website
                                    </label>
                                    <input
                                        name="portfolio"
                                        value={formData.portfolio}
                                        onChange={handleInputChange}
                                        type="url"
                                        placeholder="https://your-portfolio.com"
                                        className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                    />
                                </div>
                            </div>

                            {/* Experience Level */}
                            <div>
                                <label className="block text-sm font-medium text-slate-300 mb-2">
                                    Experience Level *
                                </label>
                                <select
                                    required
                                    name="experience"
                                    value={formData.experience}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors"
                                >
                                    <option value="">Select experience level</option>
                                    {experienceLevels.map(level => (
                                        <option key={level} value={level}>{level}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold text-blue-400 flex items-center gap-2">
                            <FileText className="w-5 h-5"/>
                            Additional Information
                        </h2>

                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-2">
                                Cover Letter
                            </label>
                            <textarea
                                name="coverLetter"
                                value={formData.coverLetter}
                                onChange={handleInputChange}
                                rows={6}
                                placeholder="Tell us why you'd be a great fit for our team..."
                                className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-2">
                                Additional Notes
                            </label>
                            <textarea
                                rows={3}
                                name="additionalNotes"
                                value={formData.additionalNotes}
                                onChange={handleInputChange}
                                placeholder="Any additional information you'd like us to know..."
                                className="w-full px-4 py-2 rounded-lg bg-slate-900/50 border border-slate-700 text-slate-300 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full py-3 px-6 rounded-lg bg-gradient-to-r from-blue-500 to-green-500 text-white font-semibold"
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Application'}
                        </button>
                        <p className="text-sm text-slate-400 text-center mt-4">
                            By submitting this application, you agree to our{" "}
                            <a
                                href="/hiring-process"
                                className="text-blue-400 hover:underline"
                            >
                                selection process
                            </a>{" "}
                            and{" "}
                            <a
                                href="/website-terms"
                                className="text-blue-400 hover:underline"
                            >
                                terms.
                            </a>
                        </p>
                    </div>
                </form>
                {/* Toast Container */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
        </div>
    );
};

export default ApplicationForm;
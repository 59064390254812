import React from 'react';

const TermsSection = ({ title, children }) => (
    <div className="backdrop-blur-sm bg-white/5 rounded-lg p-6 mb-6 border border-white/10 hover:border-white/20 transition-colors">
        <h2 className="text-2xl font-semibold mb-4 text-blue-300">{title}</h2>
        <p className="text-gray-300 leading-relaxed">{children}</p>
    </div>
);

const WebsiteTerms = () => {
    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
            <div className="max-w-4xl mx-auto px-6 sm:px-8 py-12 flex-grow">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                        Website Terms
                    </h1>
                    <p className="text-lg text-gray-300 max-w-2xl mx-auto leading-relaxed">
                        By using the Vision Decode website, you agree to the following terms and conditions.
                        Please read them carefully.
                    </p>
                </div>

                {/* Terms Sections */}
                <div className="space-y-6">
                    <TermsSection title="Acceptance of Terms">
                        These terms govern your use of our website and services. If you disagree with any part
                        of the terms, please refrain from using our website.
                    </TermsSection>

                    <TermsSection title="Use of the Website">
                        You may use the website for lawful purposes only. You are responsible for ensuring
                        your use of the site complies with all applicable laws.
                    </TermsSection>

                    <TermsSection title="Limitation of Liability">
                        We are not liable for any direct or indirect damages arising from the use of our
                        website or the information provided on it.
                    </TermsSection>
                </div>

                {/* Footer */}
                <div className="mt-12 text-center">
                    <div className="inline-block px-4 py-2 rounded-full bg-white/5 border border-white/10">
                        <p className="text-sm text-gray-400">
                            Last updated: January 2024
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebsiteTerms;